import React from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import NewsletterSignup from "../components/newsletterSignup";

class Thanks extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="Confirmed | Lewis Mohr"
          meta={[
            {
              name: "description",
              content:
                "Thanks for signing up for our newsletter about insurance in Baton Rouge, Louisiana!"
            }
          ]}
        />
        <div>
          <Img
            alt="Abstract Lighting"
            fluid={this.props.data.mainBg.childImageSharp.fluid}
            className="posts-bg"
          />
        </div>
        <div className="site-body page-content">
          <h1>Your Subscription is Confirmed!</h1>
          <h3>You'll start recieving our monthly insurance newsletters soon.</h3><br />
          <Link className="button" to={"/posts/"}>Read Our Insurance Articles</Link>
        </div>
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default Thanks;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    mainBg: file(relativePath: { eq: "abstract-bg-1.jpg" }) {
      ...mainBg
    }
  }
`;
